var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.$route.meta.type === 1)?_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-light sticky-top"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarTogglerDemo02","role":"navigation"}},[_c('ul',{staticClass:"navbar-nav ml-auto mt-2 mt-lg-0"},[_c('li',{staticClass:"nav-item"},[(_vm.$route.name === 'homePage')?_c('a',{staticClass:"nav-link",staticStyle:{"color":"white","background-color":"rgb(0, 155, 219)"},attrs:{"href":"/"}},[_vm._v(" Take Our Test ")]):_c('a',{staticClass:"nav-link",staticStyle:{"color":"rgb(0, 155, 219)"},attrs:{"href":"/"}},[_vm._v(" Take Our Test ")])]),_vm._m(2)])])]):_vm._e(),(_vm.$route.meta.type === 2)?_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-light sticky-top"},[_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.$route.meta.type === 0)?_c('p',[_vm._v("Redirecting to the quiz...")]):_vm._e(),_c('router-view'),(_vm.$route.meta.type === 1)?_c('div',{staticClass:"container",staticStyle:{"margin-top":"27px"}},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v("© Copyright "+_vm._s(new Date().getFullYear())+" Add Energy - All rights reserved.")])])])]):_vm._e(),(_vm.$route.meta.type === 2)?_c('div',{staticClass:"container",staticStyle:{"margin-top":"27px"}},[_vm._m(7),_vm._m(8),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v("© Copyright "+_vm._s(new Date().getFullYear())+" Add Energy - All rights reserved.")])])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"https://addenergy.no/service/training-and-advisory/the-add-energy-academy"}},[_c('img',{staticStyle:{"height":"100px","padding-left":"20px"},attrs:{"src":require("./assets/AELogo.png"),"alt":"Add Energy Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarTogglerDemo02","aria-controls":"navbarTogglerDemo02","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",staticStyle:{"color":"rgb(0 155 219)"},attrs:{"href":"https://addenergy.no/service/asset-and-integrity-management"}},[_vm._v(" About Add Energy ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarTogglerDemo02","aria-controls":"navbarTogglerDemo02","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarTogglerDemo02","role":"navigation"}},[_c('ul',{staticClass:"navbar-nav ml-auto mt-2 mt-lg-0"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",staticStyle:{"color":"rgb(0, 155, 219)"},attrs:{"href":"/"}},[_vm._v(" Take Our Test ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-4 mb-4"},[_c('img',{staticStyle:{"height":"100px","padding-left":"20px"},attrs:{"src":require("./assets/AELogo.png"),"alt":"Add Energy Logo"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v(" Add Energy is an international consultancy, service and software provider offering solutions across industries designed to enhance performance, assure safety integrity and optimize costs. ")]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"https://addenergy.no/about-us"}},[_vm._v("Learn more")])])])]),_c('div',{staticClass:"col-md-4 mb-4"},[_c('ul',{staticClass:"nav flex-column text-md-center"},[_c('li',{staticClass:"nav-item display-4"},[_c('a',{staticClass:"nav-link disabled pl-0 pl-md-2"},[_vm._v("What we do")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-0 pl-md-2",attrs:{"href":"https://addenergy.no/service/drilling-well-engineering-and-management-solutions"}},[_vm._v("Drilling, Well Engineering and Management Solutions")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-0 pl-md-2",attrs:{"href":"https://addenergy.no/service/asset-and-integrity-management"}},[_vm._v("Asset and Integrity Management")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-0 pl-md-2",attrs:{"href":"https://addenergy.no/service/safety-and-risk"}},[_vm._v("Safety and Risk")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-0 pl-md-2",attrs:{"href":"https://addenergy.no/service/training-and-advisory"}},[_vm._v("Training and Advisory")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pl-0 pl-md-2",attrs:{"href":"https://addenergy.no/service/decarbonization-and-energy-transition"}},[_vm._v("Decarbonization and Energy Transition")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 pl-0 mb-4 mb-sm-0"},[_c('ul',{staticClass:"nav"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://addenergy.no/contact-us"}},[_vm._v("Contact us")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://addenergy.no/privacy-policy"}},[_vm._v("Privacy policy")])])])]),_c('div',{staticClass:"col-md-4 my-auto"},[_c('div',{staticClass:"row text-sm-right d-flex justify-content-between p-2"},[_c('div',{staticClass:"mb-2 mb-sm-0"},[_c('a',{attrs:{"href":"https://www.linkedin.com/company/add-energy/","target":"_blank"}},[_c('i',{staticClass:"fa fa-fw fa-linkedin fa-lg text-secondary",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"mb-2 mb-sm-0"},[_c('a',{attrs:{"href":"https://www.facebook.com/addenergygroup/","target":"_blank"}},[_c('i',{staticClass:"fa fa-facebook fa-lg fa-fw text-secondary",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"mb-2 mb-sm-0"},[_c('a',{attrs:{"href":"https://twitter.com/add_energy","target":"_blank"}},[_c('i',{staticClass:"fa fa-fw fa-twitter fa-lg text-secondary",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"mb-2 mb-sm-0"},[_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCurfzdwCgigJRaIZoFDayZg"}},[_c('i',{staticClass:"fa fa-fw fa-youtube fa-lg text-secondary"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-4 mb-4"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 pl-0 mb-4 mb-sm-0"},[_c('p',[_vm._v("Powered by Add Energy funded doctorate research")])]),_c('div',{staticClass:"col-md-4 my-auto"},[_c('div',{staticClass:"row text-sm-right d-flex justify-content-between p-2"},[_c('div',{staticClass:"mb-2 mb-sm-0"},[_c('a',{attrs:{"href":"https://www.linkedin.com/company/ecitb/","target":"_blank"}},[_c('i',{staticClass:"fa fa-fw fa-linkedin fa-lg text-secondary",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"mb-2 mb-sm-0"},[_c('a',{attrs:{"href":"https://www.facebook.com/ECITB/","target":"_blank"}},[_c('i',{staticClass:"fa fa-facebook fa-lg fa-fw text-secondary",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"mb-2 mb-sm-0"},[_c('a',{attrs:{"href":"https://twitter.com/ECITB_Skills","target":"_blank"}},[_c('i',{staticClass:"fa fa-fw fa-twitter fa-lg text-secondary",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"mb-2 mb-sm-0"},[_c('a',{attrs:{"href":"https://www.instagram.com/ecitb_skills/"}},[_c('i',{staticClass:"fa fa-fw fa-instagram fa-lg text-secondary"})])])])])])
}]

export { render, staticRenderFns }