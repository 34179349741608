<!--Script to create and render the radar chart for the report-->
<script>
import { Radar } from "vue-chartjs";

export default {
  extends: Radar,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
      beginAtZero: true,
      stepSize: 1,
      suggestedMax: 5,
      scales: {
        yAxes: [
          {
            ticks: {
              max: 5,
              min: 0,
              stepSize: 1,
            },
          },
        ],
      },
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>

<style scoped>
</style>