<!--Home component template and styling.-->
<template>
<div>
  <div class="jumbotron vertical-center bgimg" style="height:850px;" >
    <div class="container" >
      <div class="jumbotron shadow" style="min-width:80%;">
        <h1 class="display-4">Discover opportunities to unlock business performance improvements</h1>
        <div class="lead">
          <p class="lead">
          Take 5-minutes to utilize our free online self-assessment diagnostic engine to benchmark your organizations Asset Management System against the international ISO 55000 Asset Management Standard and:  
          </p>
          <ul>
            <li>Access insights into your business personality in relation to your business’ readiness for change</li>
            <li>Discover where opportunities for improvement exist</li>
            <li>Understand where best to focus your business performance improvement efforts</li>
          </ul>
        </div>
        
        <hr class="my-4" />
        <b>What you will receive: </b>
        <p>
          Upon completion of the assessment, you will receive instant access to a high-level overview of your business maturity in relation to the ISO 55000 Asset Management Standard and recommended improvement actions to facilitate business performance improvement initiatives. 
        </p>
        



        <!-- Button trigger modal -->
        <!-- <button type="button" class="btn btn-primary btn-lg" data-toggle="modal" data-target=".bd-example-modal-lg">Get Started</button> -->
        <a class="btn btn-primary btn-lg " role="button" href='/take-the-quiz'>  
          <router-link style="color: white" to="/take-the-quiz">Get started now</router-link>
        </a>

        <!--<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
          Launch demo modal
        </button> -->

        <!-- Modal -->

        <!-- <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                        <h3 class="modal-title" id="exampleModalLongTitle">Before We Start..</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
              </div>

              <div class="modal-body font-weight-normal" style="font-size: 20px">
                  <ol>
                            <li>Review and understand the Asset Management Question</li>
                            <li>Read from the top, when you get to the range  criteria that describes your current state stop and select the ‘score’. Be honest.</li>
                            <li>Select where you think you would like to be. It is perfectly natural to default to ‘I want to be world class at this’ and score 5. However, please bear in mind that your admirable aspiration would need to pass your leaderships scrutiny. Most businesses are  effective at a maturity of 3, some get to efficient and make more money at 4.</li>
                            <li>Add any comments, please note none of the data is saved if you wish to contact us there is a form after the output is generated. If you want to save your information then please use your browser ‘save as’ function.</li>
                        </ol>
              </div>



              <div style="background-color: #e9e8e9; width: 100%; font-size: 20px">
                <div class="container" style="padding: 30px">
                  <div class="card">
                    <div class="card-header">Watch out!</div>
                    <div class="card-body">
                      <blockquote class="blockquote mb-0">
                        <p>
                          <i>
                            It would be perfectly natural to over inflate the assessment scores; the reality of the 'as is' and ‘to be’ situation may not be palatable to most. However, a frank, honest look will yield a credible 'base line' to work from along with your suggested change management plan.
                          </i>
                        </p>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <p class="lead">

                  <a class="btn btn-primary btn-lg " role="button" href='/take-the-quiz'>  
                    <router-link style="color: white" to="/take-the-quiz" data-dismiss="modal" >Take the Test >></router-link>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div> -->



      </div>
    </div>
  </div>
<div>

</div>

</div>
</template>

<script>
export default {};
</script>

<style scoped>

.btn-primary{
    background-color: #009bdb !important;
    border: 2px solid #cacaca !important;
}

.btn-primary:hover{
    background-color: #3092fc !important;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.bgimg {
    background-image: url('../assets/aebluebkg.png');
    background-size: cover;
}

a {  text-decoration: none;}

</style>
