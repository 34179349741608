import Vue from 'vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'

// Import vue components
import App from './App.vue'
import Home from './components/Home'
import indReport from './components/ReportLevel2'
import dartReport from './components/bqReport'
import Result from './components/Result'
import AboutTheAcademy from './components/AboutTheAcademy'
import redirectQuiz from './components/QuizRedirect'
import redirectDARTQuiz from './components/DARTRedirect'

Vue.prototype.$HOST_URL = 'https://www.aeacademy.cloud/'
//Vue.prototype.$HOST_URL = 'http://localhost:8080/'

// Use the two plugins (VueResource and VueRouter)
Vue.use(VueResource);
Vue.use(VueRouter);

// Disable the development warning
Vue.config.productionTip = false;

// Specify the routes of the sites and relevant components for each route
// A type is used to denote whether the footer branding should be used or not. If type :1 is used, the branding will show. 
// The conditionals are in the App.vue file through the v-if attribute on elements.
const routes = [
  { path: '/take-the-quiz', component: redirectDARTQuiz, beforeEnter(to, from, next){
    let d = new Date().getTime();
    window.location = "https://b95pwsv3djg.typeform.com/to/yLnYzvbz#idx="+d; 
  }, meta:{type: 1}},
  { path: '/industry4', component: redirectQuiz, beforeEnter(to, from, next) { 
    let d = new Date().getTime();
    window.location = "https://b95pwsv3djg.typeform.com/to/EYHdd3tC#idx="+d; 
  }, meta:{type: 0}},
  { path: '/ind4', component: indReport, meta:{type: 2}},
  { path: '/dart', component: dartReport, meta:{type: 1}},
  { path: '/about-the-academy', component: AboutTheAcademy, meta:{type: 1}},
  { path: '/submit-email/:profID', name: "submitEmail", props: true, component: Result, meta:{type: 1}},
  { path: '/', component: Home, name:"homePage", meta:{type: 1} }
]

// Create the router with the defined routes and set the routing mode to history mode.
const router = new VueRouter({
  routes,
  mode: 'history'
});

// Create a vue instance using the router and mount the vue instance onto the 'app' div in the index.html base.
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
