<!--Result component template, styling and functionality methods.
    
    Email-submission page

    This page is used between the quiz submission and the report page. 
    This is only used for the DART Quiz. Nothing else. This can be seen by the use of /myprofile/ in the redirect.
-->

<template>
  <div>
    <div class="container overallContainer">
      <div class="row">
        <div class="col-lg-10">
          <div class="row">
            <div class="container">
              <div class="card" style="border-color: white">
                <h2>
                  Please enter your email into the form to see your results!
                </h2>
                <div class="card-body" style="font-size: 20px">
                  <div id="formPlacement" v-once></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  mounted() {
    // When the component is mounted to the app and base html
    // Create a script html element and define it as a variable.
    const script = document.createElement("script");
    // Save the 'this' variable state in another variable.
    let self = this
    // Set the script html element's src attribute to the HubSpot form javascript
    script.src = "https://js.hsforms.net/forms/v2.js";
    // Attach script html element to the body
    document.body.appendChild(script);
    // On script load create the hubspot form with onFormSubmit event listener.
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "3825857",
          formId: "7f2092e6-b5a3-4326-a44a-92c151ad8b16",
          target: "#formPlacement",
          onFormSubmit: function ($form, ctx) {
            self.linkToReport = "/myprofile/" + self.$route.params.profID;
            self.$router.push({ path: self.linkToReport});
          },
        });
      }
    });
  },
};
</script>

<style scoped>
.overallContainer {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
</style>
