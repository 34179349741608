<!-- This page is used as a redirect to the DART quiz on typeform-->

<template>
  <div>
    <h1>Redirecting you to the Quiz...</h1>
  </div>
</template>
<script>

export default {

  created() {
    window.location = "https://b95pwsv3djg.typeform.com/to/yLnYzvbz";
  },

};
</script>