<!--App component template, styling and functionality methods.-->
<template>
  <div id="app">
    <!-- Navigation -->

    
    <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top"  v-if="$route.meta.type === 1">
      <a
        class="navbar-brand"
        href="https://addenergy.no/service/training-and-advisory/the-add-energy-academy"
        ><img
          src="./assets/AELogo.png"
          style="height: 100px; padding-left: 20px"
          alt="Add Energy Logo"
        />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse"
        id="navbarTogglerDemo02"
        role="navigation"
      >
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
          <li class="nav-item">
            <a class="nav-link" href='/' v-if="$route.name === 'homePage'" style="color: white; background-color: rgb(0, 155, 219);">
              Take Our Test
            </a>
            <a class="nav-link" href='/' v-else style="color: rgb(0, 155, 219);">
              Take Our Test
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              style="color: rgb(0 155 219);"
              href="https://addenergy.no/service/asset-and-integrity-management"
            >
              About Add Energy
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top"  v-if="$route.meta.type === 2">
      
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse"
        id="navbarTogglerDemo02"
        role="navigation"
      >
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
          <li class="nav-item">
            <a class="nav-link" href='/' style="color: rgb(0, 155, 219);">
              Take Our Test
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <p v-if="$route.meta.type === 0">Redirecting to the quiz...</p>

    <router-view></router-view>

    <div class="container" style="margin-top:27px" v-if="$route.meta.type === 1">
      <div class="row mb-4">
        <div class="col-md-4 mb-4">
          <img
          src="./assets/AELogo.png"
          style="height: 100px; padding-left: 20px"
          alt="Add Energy Logo"
          />
          <div class="row">
            <div class="col-md-12">
              <p>
                Add Energy is an international consultancy, service and software
                provider offering solutions across industries designed to
                enhance performance, assure safety integrity and optimize costs.
              </p>
              <a class="btn btn-primary" href="https://addenergy.no/about-us">Learn more</a>
            </div>
          </div>
        </div>

        <div class="col-md-4 mb-4">
          <ul class="nav flex-column text-md-center">
            <li class="nav-item display-4">
              <a class="nav-link disabled pl-0 pl-md-2">What we do</a>
            </li>
            <li class="nav-item">
              <a
                href="https://addenergy.no/service/drilling-well-engineering-and-management-solutions"
                class="nav-link pl-0 pl-md-2"
                >Drilling, Well Engineering and Management Solutions</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://addenergy.no/service/asset-and-integrity-management"
                class="nav-link pl-0 pl-md-2"
                >Asset and Integrity Management</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://addenergy.no/service/safety-and-risk"
                class="nav-link pl-0 pl-md-2"
                >Safety and Risk</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://addenergy.no/service/training-and-advisory"
                class="nav-link pl-0 pl-md-2"
                >Training and Advisory</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://addenergy.no/service/decarbonization-and-energy-transition"
                class="nav-link pl-0 pl-md-2"
                >Decarbonization and Energy Transition</a
              >
            </li>
          </ul>
        </div>
        
      </div>
      <div class="row">
        <div class="col-md-8 pl-0 mb-4 mb-sm-0">
          <ul class="nav">
            <li class="nav-item">
              <a class="nav-link" href="https://addenergy.no/contact-us"
                >Contact us</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://addenergy.no/privacy-policy"
                >Privacy policy</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-4 my-auto">
          <div class="row text-sm-right d-flex justify-content-between p-2">
          <div class="mb-2 mb-sm-0">
            <a
              href="https://www.linkedin.com/company/add-energy/"
              target="_blank"
              ><i
                class="fa fa-fw fa-linkedin fa-lg text-secondary"
                aria-hidden="true"
              ></i
            ></a>
          </div>
          <div class="mb-2 mb-sm-0">
            <a href="https://www.facebook.com/addenergygroup/" target="_blank"
              ><i
                class="fa fa-facebook fa-lg fa-fw text-secondary"
                aria-hidden="true"
              ></i
            ></a>
          </div>
          <div class="mb-2 mb-sm-0">
            <a href="https://twitter.com/add_energy" target="_blank"
              ><i
                class="fa fa-fw fa-twitter fa-lg text-secondary"
                aria-hidden="true"
              ></i
            ></a>
          </div>
          <div class="mb-2 mb-sm-0">
            <a href="https://www.youtube.com/channel/UCurfzdwCgigJRaIZoFDayZg"
              ><i class="fa fa-fw fa-youtube fa-lg text-secondary"></i
            ></a>
          </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <p>© Copyright {{ new Date().getFullYear() }} Add Energy - All rights reserved.</p>
        </div>
      </div>
    </div>


    <div class="container" style="margin-top:27px" v-if="$route.meta.type === 2">
      <div class="row mb-4">
        <div class="col-md-4 mb-4">
        </div>
        
      </div>
      <div class="row">
        <div class="col-md-8 pl-0 mb-4 mb-sm-0">
          <p>Powered by Add Energy funded doctorate research</p>
        </div>
        <div class="col-md-4 my-auto">
          <div class="row text-sm-right d-flex justify-content-between p-2">
          <div class="mb-2 mb-sm-0">
            <a
              href="https://www.linkedin.com/company/ecitb/"
              target="_blank"
              ><i
                class="fa fa-fw fa-linkedin fa-lg text-secondary"
                aria-hidden="true"
              ></i
            ></a>
          </div>
          <div class="mb-2 mb-sm-0">
            <a href="https://www.facebook.com/ECITB/" target="_blank"
              ><i
                class="fa fa-facebook fa-lg fa-fw text-secondary"
                aria-hidden="true"
              ></i
            ></a>
          </div>
          <div class="mb-2 mb-sm-0">
            <a href="https://twitter.com/ECITB_Skills" target="_blank"
              ><i
                class="fa fa-fw fa-twitter fa-lg text-secondary"
                aria-hidden="true"
              ></i
            ></a>
          </div>
          <div class="mb-2 mb-sm-0">
            <a href="https://www.instagram.com/ecitb_skills/"
              ><i class="fa fa-fw fa-instagram fa-lg text-secondary"></i
            ></a>
          </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <p>© Copyright {{ new Date().getFullYear() }} Add Energy - All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./assets/vendor/bootstrap/js/bootstrap.min.js";

// Export the app component
export default {
  name: "App",

  //HOST_URL: $HOST_URL,
  //TAKE_QUIZ_URL: this.HOST_URL + '/take-the-quiz',

  beforeCreate() {},
  //components: {
  //  Report,
  //  Quiz
  //},
};
</script>

<!--CSS unique to where the app component is used. 'scoped' makes it unique to the component-->

<style scoped lang="scss">
@import "./assets/vendor/bootstrap/css/bootstrap.min.css";

.display-4{
  font-size: 2rem;
}

.p-2 {
  padding: 0.8rem !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.fa-youtube:before {
  content: "\f167";
}

body {
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.text-sm-right {
  text-align: right !important;
}

.btn-outline-primary {
  border: 2px solid rgb(64, 1, 94) !important;
}

.btn-outline-primary:hover {
  background-color: #e4e4e4;
  color: white !important;
}

.btn-primary{
  background-color: #009bdb!important;
}

.router-link-exact-active {
  background-color: #009bdb!important;
  padding: 0.5rem;
  color: white !important;
}
</style>
